import React from "react"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"
import styled from "styled-components"

const ReactPlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  aspect-ratio: 16 / 9;
  box-sizing: border-box;
`
const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`
const Video = ({ url, controls, poster, muted, loop, play }) => {
  return (
    <ReactPlayerWrapper>
      <StyledReactPlayer
        url={url}
        light={poster}
        controls={controls}
        volume={1}
        playing={play}
        muted={muted}
        loop={loop}
        width="100%"
        height="100%"
      />
    </ReactPlayerWrapper>
  )
}

export default Video

Video.propTypes = {
  controls: PropTypes.bool,
  url: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  play: PropTypes.bool,
}

Video.defaultProps = {
  controls: true,
  muted: false,
  loop: false,
  play: false,
}
