import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledHeading = styled.h1`
  letter-spacing: 0.18em;
  text-transform: uppercase;
  line-height: 1.22;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 50px;
`
const Title = styled.div`
  font-weight: 400;
  font-size: 18px;
`

const PageHeader = ({ title, suptitle, subtitle, headerText }) => {
  return (
    <>
      <Title>
        {suptitle}
        <StyledHeading>{title}</StyledHeading>
        {subtitle}
        <p>{headerText}</p>
      </Title>
    </>
  )
}

export default PageHeader

PageHeader.propTypes = {
  suptitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  headerText: PropTypes.string,
}
