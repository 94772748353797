import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import { mediaQuery } from "../../../theme.js"

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;

  ${mediaQuery("small")`
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`

const Image = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 120px;

  ${mediaQuery("medium")`
    max-width: 200px;
  `}
`
const StyledWrapper = styled.section`
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LogoGrid = ({ contents }) => {
  return (
    <Grid>
      {contents.map((logo, i) => {
        let image

        if (typeof logo.image === "string") {
          image = <Image src={logo.image} alt={logo.alt} />
        } else {
          // this is a react component
          image = logo.image
        }

        if (logo.to) {
          image = <Link to={logo.to}>{image}</Link>
        }

        if (logo.href) {
          image = (
            <a href={logo.href} target="_blank" rel="noreferrer">
              {image}
            </a>
          )
        }

        return <StyledWrapper key={i}>{image}</StyledWrapper>
      })}
    </Grid>
  )
}
export default LogoGrid

LogoGrid.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      alt: PropTypes.string,
      to: PropTypes.string,
      href: PropTypes.string,
    })
  ),
}
