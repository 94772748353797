import React from "react"
import { Header, Footer } from "@bw/layouts"
import { Seo } from "@bw/bits"
import styled from "styled-components"
import Logo from "../../../images/logo"

const StyledMain = styled.main`
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: auto;
  overflow: hidden;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
`
const Layout = ({ children, noFooter = false }) => {
  return (
    <>
      <Seo />
      <Header
        logo={<Logo />}
        menu={[
          { to: "/", label: "Chez l’Épicier" },
          { to: "/partenaires/", label: "Nos partenaires" },
          { to: "/contact/", label: "Contact" },
        ]}
      />
      <StyledMain>{children}</StyledMain>
      {noFooter === false && (
        <Footer
          menu={[
            { to: "/", label: "Accueil" },
            { to: "/partenaires/", label: "Nos partenaires" },
            { to: "/contact/", label: "Contact" },
          ]}
        />
      )}
    </>
  )
}

export default Layout
