import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

const buttonStyle = css`
  ${props => {
    let color = `var(--${props.$color}, ${props.$color})`
    if (props.$color[0] === "#" || props.$color.indexOf("rgb") > -1) {
      color = props.$color
    }
    return `--color: ${color};`
  }}
  --text-color: #fff;

  text-decoration: none;
  display: inline-flex;
  font-family: var(--font-body);
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.025em;
  cursor: pointer;
  transition: top 250ms, left 250ms;
  top: -5px;
  left: -5px;
  position: relative;
  padding: 15px 19px;
  min-width: 200px;
  background-color: ${props =>
    props.$inversed ? "var(--text-color)" : "var(--color)"};
  color: ${props => (props.$inversed ? "var(--color)" : "var(--text-color)")};
  border: 2px solid
    ${props => (props.$inversed ? "var(--text-color)" : "var(--color)")};

  &:hover,
  &:focus {
    top: 0;
    left: 0;
  }

  &::after {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    width: 100%;
    height: 100%;
    background-color: $white;
    border: 3px solid
      ${props => (props.$inversed ? "var(--text-color)" : "var(--color)")};
    transition: transform 250ms;
  }
  &:hover::after,
  &:focus::after {
    transform: translate(-12px, -12px);
  }
`

const StyledLink = styled(Link)`
  ${buttonStyle}
`

const StyledAnchor = styled.a`
  ${buttonStyle}
`

const StyledButton = styled.button`
  ${buttonStyle}
`

const Button = props => {
  if (props.to) {
    return (
      <StyledLink
        to={props.to}
        $inversed={props.inversed}
        $color={props.color}
        style={props.style}
      >
        {props.icon}
        {props.label}
      </StyledLink>
    )
  }

  if (props.href) {
    return (
      <StyledAnchor
        href={props.href}
        $inversed={props.inversed}
        $color={props.color}
        style={props.style}
        target="_blank"
        rel="noopener noreferer"
      >
        {props.icon}
        {props.label}
      </StyledAnchor>
    )
  }

  if (props.anchorElement) {
    return (
      <StyledButton
        onClick={() => {
          const to = 1000
          const duration = 1000

          const element = document.scrollingElement || document.documentElement,
            start = element.scrollTop,
            change = to - start,
            startDate = +new Date(),
            // t = current time
            // b = start value
            // c = change in value
            // d = duration
            easeInOutQuad = function (t, b, c, d) {
              t /= d / 2
              if (t < 1) return (c / 2) * t * t + b
              t--
              return (-c / 2) * (t * (t - 2) - 1) + b
            },
            animateScroll = function () {
              const currentDate = +new Date()
              const currentTime = currentDate - startDate
              element.scrollTop = parseInt(
                easeInOutQuad(currentTime, start, change, duration)
              )
              if (currentTime < duration) {
                requestAnimationFrame(animateScroll)
              } else {
                element.scrollTop = to
              }
            }
          animateScroll()
        }}
        $inversed={props.inversed}
        $color={props.color}
        style={props.style}
      >
        {props.icon}
        {props.label}
      </StyledButton>
    )
  }

  return (
    <StyledButton
      onClick={props.onClick}
      $inversed={props.inversed}
      $color={props.color}
      style={props.style}
    >
      {props.icon}
      {props.label}
    </StyledButton>
  )
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.element,
  inversed: PropTypes.bool,
  color: PropTypes.string,
}

Button.defaultProps = {
  color: "primary",
  inversed: false,
  icon: null,
}

export default Button
