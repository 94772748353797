import React from "react"
import PropTypes from "prop-types"
import { Carousel, Grid, ResponsiveContext } from "grommet"
import { splitEvery } from "ramda"
import styled from "styled-components"

const Teasers = ({ displayType, numberOfColumns, children }) => {
  const size = React.useContext(ResponsiveContext)

  const StyledWrapper = styled.section`
    max-width: 100%;
    height: auto;
    overflow: hidden;
  `

  let layout = ["1fr"]

  if (numberOfColumns === 3) {
    layout =
      size === "large" || size === "xlarge"
        ? ["1fr", "1fr", "1fr"]
        : ["1fr", "1fr"]
  }

  if (numberOfColumns === 2 && size !== "small") {
    layout = ["1fr", "1fr"]
  }

  switch (displayType) {
    case "carousel":
      const items = splitEvery(layout.length, children)
      return (
        <StyledWrapper>
          <Carousel fill controls="arrows">
            {items.map(slide => (
              <Grid columns={layout} gap="large" pad="large">
                {slide}
              </Grid>
            ))}
          </Carousel>
        </StyledWrapper>
      )

    case "grid":
    default:
      return (
        <Grid columns={layout} gap="large">
          {children}
        </Grid>
      )
  }
}

export default Teasers

Teasers.propTypes = {
  displayType: PropTypes.oneOf(["grid", "carousel"]),
  numberOfColumns: PropTypes.number,
}

Teasers.defaultProps = {
  numberOfColumns: 3,
  displayType: "grid",
}
