import React from "react"
import PropTypes from "prop-types"
import { breakpoints } from "../../../theme"
import styled from "styled-components"

const Grid = styled.div`
  width: 100%;
  display: grid;
  column-gap: 24px;
  row-gap: 16px;
  padding: ${props => props.$padding};
  align-items: flex-start;

  @media (min-width: ${breakpoints.medium}px) {
    column-gap: 48px;
    row-gap: 96px;
    grid-template-columns: repeat(${props => props.$count}, 1fr);
  }
`

const Columns = ({ count, contents, children, padding }) => {
  return (
    <Grid $count={count} $padding={padding}>
      {children ||
        contents.map((item, i) => (
          <div key={i}>
            {item.title && <h3>{item.title}</h3>}
            {item.content && <div>{item.content}</div>}
          </div>
        ))}
    </Grid>
  )
}

export default Columns

Columns.propTypes = {
  count: PropTypes.number,
  margin: PropTypes.string,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
    })
  ),
}

Columns.defaultProps = {
  count: 2,
}
