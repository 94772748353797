import React from "react"
import PropTypes from "prop-types"

const elements = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  div: 'div',
  span: 'span'
};

function CustomTag({ type, children, ...props }) {    
  return React.createElement(
    elements[type] || elements.h2, 
    props, 
    children
  );
}

export default CustomTag

CustomTag.propTypes = {
  type: PropTypes.string,
}

CustomTag.defaultProps = {
  type: 'h2',
};
