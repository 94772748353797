import React from "react"
import PropTypes from "prop-types"
import { Title, Section } from "@bw/bits"
import styled from "styled-components"
import { breakpoints } from "../../../theme"

const HeroImage = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background-image: url("${props => props.$backgroundImage}");
  background-size: cover;
  background-position: center center;
`
const HeroImageblur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, var(--primary), rgba(5, 6, 14, 0.60));

  @media (min-width: ${breakpoints.medium}px) {
    background: rgba(5, 6, 14, 0.60);
  }
`

const StyledDiv = styled.div`
  width: 100%;
  max-width: ${props => props.contentSize};

  svg {
    max-width: 50%;
    height: auto;
    margin: auto;

    @media (min-width: ${breakpoints.medium}px) {
      max-width: 75%;
    }
  }
`

const StyledHeading = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 1.22;
  margin: 10px 0;
  color: ${props => (props.inverse ? "#ffffff" : "#000000")};
`

const StyledWrapper = styled.section`
  max-width: 100%;
  display: flex;
  justify-content: ${props => (props.justify ? props.justify : "")};
`

const StyledExcerpt = styled.div`
  font-family: var(--font-body);
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 45px;
  color: #ffffff;
`

const Hero = ({
  contentSize,
  inverse,
  button,
  buttonPosition,
  background,
  backgroundImage,
  suptitle,
  title,
  excerpt,
}) => {
  return (
    <HeroImage $backgroundImage={backgroundImage}>
      {backgroundImage && (
        <HeroImageblur/>
      )}
      <Section
        background={backgroundImage ? "" : background}
        grunge="after"
        padding="150px 0 200px"
        noMargin
      >
        <StyledDiv contentSize={contentSize}>
          <StyledHeading>
            <Title
              suptitle={suptitle}
              title={title}
              inverse={inverse}
              type="h1"
            />
          </StyledHeading>
          <StyledExcerpt>{excerpt}</StyledExcerpt>
          {button && (
            <StyledWrapper justify={buttonPosition}>{button}</StyledWrapper>
          )}
        </StyledDiv>
      </Section>
    </HeroImage>
  )
}

export default Hero

Hero.propTypes = {
  inverse: PropTypes.bool,
  suptitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  button: PropTypes.element,
  backgroundImage: PropTypes.string,
  background: PropTypes.string.isRequired,
  buttonPosition: PropTypes.oneOf(["start", "center", "end"]),
  contentSize: PropTypes.string,
}

Hero.defaultProps = {
  buttonPosition: "start",
  inverse: true,
  contentSize: "700px"
}
