import React from "react"
import { Link } from "gatsby"
import { Container } from "@bw/bits"
import styled from "styled-components"
import { breakpoints } from "../../../theme"

const StyledWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.medium}px) {
    flex-direction: row;
  }
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.medium}px) {
    flex-direction: row;
  }
`

const StyledFooter = styled.footer`
  color: #ffffff;
  background-color: var(--primary);
  padding: 45px 0;
  display: flex;
  justify-content: center;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 0px 100px 0 0;
  font-family: var(--font-body);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  margin: 15px 0;

  &:hover {
    opacity: 50%;
  }

  @media (min-width: ${breakpoints.medium}px) {
    margin: 0 3vw;
  }
`
const StyledSpan = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 190.4%;
  margin: 45px 0;

  @media (min-width: ${breakpoints.medium}px) {
    margin: 0 3vw;
  }
`

const StyledBW = styled.a`
  text-decoration: none;
  color: #ffffff;
  font-weight: 700;
`

const activeStyle = {
  fontWeight: 800,
}

const Links = ({ menu }) => {
  return (
    <StyledWrapper>
      <StyledDiv>
        {menu.map((item, i) => (
          <StyledLink key={i} to={item.to} activeStyle={activeStyle}>
            {item.label}
          </StyledLink>
        ))}
      </StyledDiv>
      <StyledSpan>
        Site internet par{" "}
        <StyledBW
          href="https://www.bureauweb.ch/"
          target="_blank"
          rel="noreferrer"
        >
          Le Bureau Web
        </StyledBW>
      </StyledSpan>
    </StyledWrapper>
  )
}

const Footer = ({ menu }) => {
  return (
    <StyledFooter>
      <Container>
        <Links menu={menu} />
      </Container>
    </StyledFooter>
  )
}

export default Footer
