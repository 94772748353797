import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const StyledContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;

  position: relative;
  max-width: ${props =>
    props.$size === "small"
      ? "768px"
      : props.$size === "medium"
      ? "996px"
      : "1200px"};
`

const Container = ({ size, children }) => {
  return <StyledContainer $size={size}>{children}</StyledContainer>
}

export default Container

Container.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
}

Container.defaultProps = {
  size: "large",
}
