import React from "react"
import PropTypes from "prop-types"
import { Title, Container } from "@bw/bits"
import styled from "styled-components"
import { breakpoints } from "../../../theme"
import taches from "./taches.svg"
import grungeTop from "./grunge-top.svg"
import grungeBottom from "./grunge-bottom.svg"

const StyledSection = styled.section`
  position: relative;
  margin-top: ${props =>
    props.$grunge === "before" || props.$noMargin ? "0" : "60px"};
  margin-bottom: ${props =>
    props.$grunge === "after" || props.$noMargin ? "0" : "60px"};
  width: 100%;
  background-color: ${props => props.$background};
  background-image: ${props => (props.$pattern ? `url(${taches})` : null)};
  background-size: cover;
  background-repeat: repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${props =>
    props.$fillInverse ? "color: #fff;background-color: var(--primary);" : ""}
  padding: ${props => props.$padding};
  z-index: ${props => props.$zIndex};

  ${props => {
    if (props.$decoration) {
      return `
      ::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: ;
        left: -10px;
        width: 120%;
        height: 100px;
        background-color: #fff;
        transform-origin: top right;
        transform: translateY(-110%) rotate(-1deg);
      }`
    }
  }}

  @media (min-width: ${breakpoints.small}px) {
    margin-top: ${props =>
      props.$grunge === "before" || props.$noMargin === true ? "0" : "75px"};
    margin-bottom: ${props =>
      props.$grunge === "after" || props.$noMargin ? "0" : "75px"};
  }
`
const TitleWrapper = styled.div`
  ${props =>
    props.$justify === "center" &&
    `
    display:flex;
    flex-direction: row;
    justify-content: center;
  `}
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.$justify};
  margin-top: 45px;
`

const Grid = styled.div`
  @media (min-width: ${breakpoints.medium}px) {
    display: grid;
    grid-template-columns: 2fr 3fr;
  }
  @media (min-width: ${breakpoints.medium}px) {
    grid-template-columns: 1fr 2fr;
  }
`

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, var(--primary), rgba(5, 6, 14, 0.52));

    @media (min-width: ${breakpoints.medium}px) {
      background: rgba(5, 6, 14, 0.52);
    }
  }
`

const StyledPattern = styled.div`
  position: absolute;
  bottom: ${props => (props.$grunge === "after" ? "-3px" : null)};
  top: ${props => (props.$grunge === "before" ? "-3px" : null)};
  width: 100%;
  height: 250px;
  ${props =>
    props.$fillInverse ? "color: #fff;background-color: var(--primary);" : ""}
  background-image: ${props =>
    props.$grunge === "before"
      ? `url(${grungeTop})`
      : props.$grunge === "after"
      ? `url(${grungeBottom})`
      : null};
  background-size: cover;

  @media (min-width: ${breakpoints.large}px) {
    bottom: ${props => (props.$grunge === "after" ? "-50px" : null)};
    top: ${props => (props.$grunge === "before" ? "-50px" : null)};
  }

  @media (min-width: ${breakpoints.xlarge}px) {
    bottom: ${props => (props.$grunge === "after" ? "-80px" : null)};
    top: ${props => (props.$grunge === "before" ? "-80px" : null)};
  }
`

const Section = ({
  id,
  suptitle,
  title,
  subtitle,
  backgroundImage,
  fullWidth,
  containerSize,
  children,
  button,
  buttonPosition,
  titleOnSide,
  background,
  pattern,
  grunge,
  fillInverse,
  justify,
  padding,
  noMargin,
  decoration,
  lines,
  zAxis,
}) => {
  if (titleOnSide) {
    return (
      <StyledSection
        id={id}
        $background={background}
        $decoration={decoration}
        $pattern={pattern}
        $grunge={grunge}
        $padding={padding}
        $noMargin={noMargin}
        $fillInverse={fillInverse}
        $zIndex={zAxis === "front" ? 2 : null}
      >
        {backgroundImage && (
          <BackgroundWrapper>{backgroundImage}</BackgroundWrapper>
        )}
        <Container size={containerSize} justify={justify} style={{ zIndex: 1 }}>
          <Grid>
            {(suptitle || title) && (
              <Title title={title} suptitle={suptitle} subtitle={subtitle} />
            )}
            <div>{children}</div>
          </Grid>
        </Container>
        {grunge && (
          <StyledPattern $grunge={grunge} $fillInverse={fillInverse} />
        )}
      </StyledSection>
    )
  }

  const content = fullWidth ? (
    children
  ) : (
    <Container size={containerSize}>{children}</Container>
  )

  return (
    <StyledSection
      id={id}
      $background={background}
      $decoration={decoration}
      $pattern={pattern}
      $grunge={grunge}
      $padding={padding}
      $noMargin={noMargin}
      $fillInverse={fillInverse}
      $zIndex={zAxis === "front" ? 2 : null}
    >
      {backgroundImage && (
        <BackgroundWrapper>{backgroundImage}</BackgroundWrapper>
      )}
      {(suptitle || title) && (
        <Container size={containerSize} style={{ zIndex: 1 }}>
          <TitleWrapper $justify={justify}>
            <Title
              title={title}
              suptitle={suptitle}
              subtitle={subtitle}
              lines={justify === "center" && lines}
            />
          </TitleWrapper>
        </Container>
      )}
      {content}
      {button && (
        <Container size={containerSize} justify={justify}>
          <ButtonWrapper $justify={buttonPosition}>{button}</ButtonWrapper>
        </Container>
      )}
      {grunge && <StyledPattern $grunge={grunge} $fillInverse={fillInverse} />}
    </StyledSection>
  )
}

export default Section

Section.propTypes = {
  id: PropTypes.string,
  suptitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  fullWidth: PropTypes.bool,
  titleOnSide: PropTypes.bool,
  button: PropTypes.element,
  buttonPosition: PropTypes.oneOf(["center", "end"]),
  containerSize: PropTypes.oneOf(["small", "medium", "large"]),
  grunge: PropTypes.oneOf(["before", "after", null]),
  background: PropTypes.string,
  backgroundImage: PropTypes.element,
  decoration: PropTypes.bool,
  pattern: PropTypes.bool,
  fillInverse: PropTypes.bool,
  justify: PropTypes.oneOf(["between", "center"]),
  padding: PropTypes.string,
  noMargin: PropTypes.bool,
  lines: PropTypes.bool,
  zAxis: PropTypes.oneOf("front", "behind"),
}

Section.defaultProps = {
  buttonPosition: "center",
  containerSize: "large",
  justify: "between",
  pattern: false,
  decoration: false,
  grunge: null,
  noMargin: false,
  fillInverse: false,
  lines: false,
  zAxis: "behind",
}
