import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { breakpoints } from "../../../theme"
import yellowPattern from "./pattern-yellow.svg"
import greenPattern from "./pattern-green.svg"

const CardWrapper = styled.div`
  width: auto;
  max-width: 420px;
  min-width: 300px;
  height: auto;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 35px rgba(47, 27, 37, 0.1);
  position: relative;
  z-index: 100;

  @media (min-width: ${breakpoints.medium}px) {
    width: auto;
    max-width: 420px;
    min-width: 420px;
    display: inline-block;
  }

  ${props =>
    props.pattern &&
    css`
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 20px;
    z-index: -1;
    background-color: ${props.$patternBackground};
    background-image: url(${
      props.pattern === "green" ? greenPattern : yellowPattern
    });
    ${props.direction}: 20px;

  @media (min-width: ${breakpoints.medium}px) {
    top: 40px;
    ${props.direction}: 40px;
  }
`}
`
const CardContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 32px;
  font-size: 14px;
  line-height: 26px;
  background: #ffffff;
`

const Card = ({
  children,
  imageURL,
  type,
  pattern,
  patternBackground,
  direction,
  alt,
}) => {
  return (
    <CardWrapper
      pattern={pattern}
      direction={direction}
      $patternBackground={patternBackground}
    >
      {children && type === "text" && <CardContent>{children}</CardContent>}
      {imageURL && type === "image" && imageURL}
    </CardWrapper>
  )
}

export default Card

Card.propTypes = {
  type: PropTypes.oneOf(["image", "text"]),
  imageURL: PropTypes.element,
  alt: PropTypes.string,
  pattern: PropTypes.oneOf(["green", "yellow"]),
  patternBackground: PropTypes.oneOf(["transparent", "white"]),
  direction: PropTypes.oneOf(["left", "right"]),
}

Card.defaultProps = {
  type: "text",
  direction: "right",
  patternBackground: "transparent",
}
