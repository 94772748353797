import React from "react"
import PropTypes from "prop-types"
import { CustomTag } from "@bw/bits"
import styled, { css } from "styled-components"
import { breakpoints } from "../../../theme"
import brushImage from "./brush.svg"

const StyledHeading = styled.div`
  position: ${props => (props.$lines ? "static" : "relative")};
  font-family: var(--font-title);
  font-size: 40px;
  line-height: 36px;
  font-weight: 400;
  display: inline-flex;
  flex-direction: column;
  color: ${props =>
    props.$inverse && !props.$brush ? "#ffffff" : "var(--primary)"};

  @media (min-width: ${breakpoints.medium}px) {
    font-size: 52px;
    line-height: 56px;

    ${props => {
      if (props.$lines === true) {
        return `
        &:before {
          content: "";
          position: absolute;
          bottom: 50%;
          display: block;
          z-index: 1;
          width: 15%;
          height: 700px;
          left: 16%;
          border-bottom: 2px solid currentColor;
          border-left: 2px solid currentColor;
        }
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          display: block;
          z-index: 1;

          width: 15%;
          height: 1000px;
          right: 16%;
          border-top: 2px solid currentColor;
          border-right: 2px solid currentColor;
        }
      `
      }
    }}
  }
`

const WrapTitle = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: baseline;
  flex-direction: ${props => (props.$row ? "row" : "column")};
  margin: ${props => (props.brush ? "0 0 0 50px" : "")};
`

const Brush = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 130px;
  background-image: url(${brushImage});
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate(-15%, -15%);

  @media (min-width: ${breakpoints.medium}px) {
    transform: translate(-15%, -20%);
  }
`

const StyledTag = styled(CustomTag)`
  font-size: 44px;
  line-height: 52px;
  font-weight: 900;
  margin: 0;

  @media (min-width: ${breakpoints.medium}px) {
    font-size: 65px;
    line-height: 67px;
  }
`

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;

  position: absolute;
  top: 7px;
  left: 30px;
  align-items: center;
  transform: translate(-50%, -100%);
  ${props =>
    props.$row &&
    css`
      left: 20%;
    `}

  @media (min-width: ${breakpoints.large}px) {
    ${props =>
      props.$row &&
      css`
        left: 178px;
      `}
  }

  &:after {
    display: flex;
    content: "";
    width: 2px;
    height: 30px;
    margin: 10px auto;

    background-color: ${props =>
      props.$inverse ? "#ffffff" : "var(--primary)"};
  }
`

const Title = ({ suptitle, title, inverse, row, type, brush, icon, lines }) => {
  return (
    <StyledHeading $inverse={inverse} $brush={brush} $lines={lines}>
      {icon && (
        <Icon $inverse={inverse} $row={row}>
          {icon}
        </Icon>
      )}
      {brush && <Brush />}
      <WrapTitle $row={row}>
        {suptitle && suptitle}&nbsp;
        <StyledTag type={type}>{title}</StyledTag>
      </WrapTitle>
    </StyledHeading>
  )
}

export default Title

Title.propTypes = {
  suptitle: PropTypes.string,
  title: PropTypes.string,
  row: PropTypes.bool,
  inverse: PropTypes.bool,
  type: PropTypes.string,
  brush: PropTypes.bool,
  icon: PropTypes.element,
  lines: PropTypes.bool,
}

Title.defaultProps = {
  row: false,
  brush: false,
  inverse: false,
  type: "h2",
  lines: false,
}
