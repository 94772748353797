import React from "react"
import styled from "styled-components"
import { Container } from "@bw/bits"
import { motion } from "framer-motion"

const Layer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
  width: 100%;
`

const variants = {
  open: { opacity: 1, y: 0, display: "block" },
  closed: { opacity: 0, y: "-40px", transitionEnd: { display: "none" } },
}

const LayerMenu = ({ children, show, setShow }) => {
  return (
    <Layer
      animate={show ? "open" : "closed"}
      variants={variants}
      initial={false}
    >
      <Container>
        <Header>
          <div
            role="button"
            tabIndex="0"
            onKeyDown={() => {
              setShow(false)
            }}
            onClick={() => {
              setShow(false)
            }}
          >
            <svg ariaLabel="Close" viewBox="0 0 24 24" height="24" width="24">
              <path
                fill="none"
                stroke="#000"
                strokeWidth="2"
                d="m3 3 18 18M3 21 21 3"
              />
            </svg>
          </div>
        </Header>
        {children}
      </Container>
    </Layer>
  )
}

export default LayerMenu
