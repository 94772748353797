import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { breakpoints } from "../../../theme"
import { Columns } from "@bw/modules"

const InversedText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #1a1c30;

  @media (min-width: ${breakpoints.medium}px) {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }
`

const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #1a1c30;
`

const InversedImage = styled.div`
  @media (min-width: ${breakpoints.medium}px) {
    align-self: end;
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
`

const Line = styled.div`
  height: 95px;
  width: 1px;
  transform: translateX(calc(-100% + 30px));
  ${props =>
    props.$inversed
      ? "border-right: 2px solid currentColor;"
      : "border-left: 2px solid currentColor;"}

  @media (min-width: ${breakpoints.medium}px) {
    width: 173px;
    border-bottom: 2px solid currentColor;
  }
`

const ContentWithImage = ({ text, image, title, orientation, withLine }) => {
  if (orientation === "text_on_left") {
    return (
      <Columns>
        <Text>
          {title}
          {text}
          {withLine && <Line />}
        </Text>
        <div>{image}</div>
      </Columns>
    )
  }

  return (
    <Columns>
      <InversedText>
        {title}
        {text}
        {withLine && <Line $inversed />}
      </InversedText>
      <InversedImage>{image}</InversedImage>
    </Columns>
  )
}

export default ContentWithImage

ContentWithImage.propTypes = {
  title: PropTypes.object,
  text: PropTypes.string,
  image: PropTypes.object,
  orientation: PropTypes.oneOf(["text_on_left", "text_on_right"]),
  withLine: PropTypes.bool,
}

ContentWithImage.defaultProps = {
  orientation: "text_on_left",
  withLine: false,
}
