import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Img = styled.img`
  width: ${props => props.width};
  height: ${props => props.height};
  display: block;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Image = ({ image, alt, legend, height, width }) => {
  return (
    <>
      <Img src={image} alt={alt} height={height} width={width} />
      {/* <p>{legend}</p> */}
    </>
  )
}

export default Image

Image.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  legend: PropTypes.string,
  alt: PropTypes.string.isRequired,
}

Image.defaultProps = {}
