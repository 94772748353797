import React from "react"
import PropTypes from "prop-types"

const Dots = ({ fillColor }) => (
  <svg
    width="3"
    height="12"
    viewBox="0 0 3 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="1.60394"
      cy="1.33333"
      rx="1.33257"
      ry="1.33333"
      fill={fillColor}
    />
    <ellipse
      cx="1.60394"
      cy="5.99999"
      rx="1.33257"
      ry="1.33333"
      fill={fillColor}
    />
    <ellipse
      cx="1.60394"
      cy="10.6667"
      rx="1.33257"
      ry="1.33333"
      fill={fillColor}
    />
  </svg>
)
export default Dots

Dots.propTypes = {
  fillColor: PropTypes.string,
}

Dots.defaultProps = {
  fillColor: "#1A1C30",
}
