import React from "react"
import PropTypes from "prop-types"
import { Gallery as PhotoSwipe, Item } from "react-photoswipe-gallery"
import styled, { createGlobalStyle } from "styled-components"
import "photoswipe/dist/photoswipe.css"
import "photoswipe/dist/default-skin/default-skin.css"

const ImageStyle = createGlobalStyle`
  .pswp__img {
    object-fit: contain
  }
`

const StyledImage = styled.img`
  aspect-ratio: 16 / 9;
  object-fit: cover;
  width: 100%;
  cursor: pointer;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

const Gallery = ({ images }) => {
  return (
    <PhotoSwipe
      shareButton={false}
      fullscreenButton={false}
      options={{
        hideAnimationDuration: 0,
        showAnimationDuration: 0,
      }}
    >
      <ImageStyle />
      <Grid>
        {images.map(image => (
          <Item
            original={image.image}
            thumbnail={image.thumbnail}
            width="1024"
            height="768"
          >
            {({ ref, open }) => (
              <StyledImage
                ref={ref}
                onClick={open}
                src={image.thumbnail}
                alt=""
              />
            )}
          </Item>
        ))}
      </Grid>
    </PhotoSwipe>
  )
}

export default Gallery

Gallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      thumbnail: PropTypes.string,
      image: PropTypes.string,
    })
  ),
}
