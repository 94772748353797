import { Link } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import { Container } from "@bw/bits"
import LayerMenu from "./LayerMenu"
import Dots from "./menu-dots"
import Logo from "../../../images/logo"
import { breakpoints } from "../../../theme"

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  color: #fff;
  background-color: transparent;
  transition: background-color 0.2s;
  z-index: 250;
`

const LinkCSS = css`
  text-decoration: none;
  font-family: var(--font-body);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: currentColor;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 50%;
  }
`

const StyledLink = styled(Link)`
  ${LinkCSS}
  display: none;

  @media (min-width: ${breakpoints.medium}px) {
    display: block;
  }
`

const MobileLink = styled(Link)`
  ${LinkCSS}
  display: block;
  font-size: 32px;
  margin: 40px 0;
  color: var(--primary);
`

const StyledHomeLink = styled(Link)`
  text-decoration: none;
  color: currentColor;

  svg {
    @media (max-width: ${breakpoints.medium}px) {
      width: 150px;
      height: auto;
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 10px 0;
  width: 100%;

  @media (min-width: ${breakpoints.small}px) {
    padding: 20px 0;
  }
`

const RightMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 60px;

  @media (min-width: ${breakpoints.medium}px) {
    gap: 100px;
  }
`

const activeStyle = {
  fontWeight: 800,
}

const Burger = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 80px;
  background: none;
  align-items: center;
  border: none;
  text-decoration: none;
  font-family: var(--font-body);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: currentColor;

  @media (min-width: ${breakpoints.medium}px) {
    display: none;
  }
`

const Links = ({ mode, menu }) => {
  const MenuLink = mode === "desktop" ? StyledLink : MobileLink
  return (
    <>
      {menu.map((item, i) => (
        <MenuLink key={i} to={item.to} activeStyle={activeStyle}>
          {item.label}
        </MenuLink>
      ))}
    </>
  )
}

const Header = ({ menu }) => {
  const [show, setShow] = React.useState(false)
  const headerRef = React.useRef(null)

  const scrollBehaviour = () => {
    const header = headerRef.current
    if (window.scrollY > 10) {
      header.style.backgroundColor = "#fff"
      header.style.color = "var(--primary)"
    } else {
      header.style.backgroundColor = "transparent"
      header.style.color = "#fff"
    }
  }

  React.useEffect(() => {
    scrollBehaviour()
    window.addEventListener("scroll", scrollBehaviour)

    return () => {
      window.removeEventListener("scroll", scrollBehaviour)
    }
  }, [])

  return (
    <StyledHeader ref={headerRef}>
      <Container>
        <Grid>
          <StyledHomeLink to="/">
            <Logo fillColor="currentColor" />
          </StyledHomeLink>
          <RightMenu>
            <Links mode="desktop" menu={menu} />
            <Burger
              onClick={() => {
                setShow(true)
              }}
            >
              <Dots fillColor="currentColor" /> Menu
            </Burger>
          </RightMenu>
        </Grid>
      </Container>
      <LayerMenu show={show} setShow={setShow}>
        <Links mode="mobile" menu={menu} />
      </LayerMenu>
    </StyledHeader>
  )
}

export default Header
