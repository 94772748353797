import React from "react"
import PropTypes from "prop-types"

const Content = ({ text, children }) => {
  return <div>{children || text}</div>
}

export default Content

Content.propTypes = {
  text: PropTypes.string,
}
