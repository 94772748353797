import React from "react"
import PropTypes from "prop-types"
import { Button } from "@bw/bits"
import styled from "styled-components"
import { Link } from "gatsby"
import { mediaQuery as MQ } from "../../../theme";

const StyledWrapper = styled.section`
  max-width: 100%;
  display: flex;
  border-radius: 24px;
  padding: 24px;

  ${MQ("medium")`
    padding: 48px;
  `}

  ${MQ("large")`
    padding: 96px;
  `}

  ${props => (
    props.background ? 
    `background: ${props.background};`
    : ""
  )}
`
const StyledText = styled.p`
  margin: 0 0 24px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledA = styled.a`
  text-decoration: none;
`

const StyledCtA = styled.div`
  color: ${props => (props.textColor === "positive" ? "#000000" : "#ffffff")};
`

const CallToAction = ({
  background,
  suptitle,
  title,
  subtitle,
  buttonLabel,
  to,
  href,
  onClick,
  textColor,
}) => {
  const Cta = ({ onClickEvent }) => (
    <StyledWrapper
      onClick={onClickEvent}
      background={background}
    >
      <StyledCtA textColor={textColor}>
        {suptitle && (<StyledText>
          {suptitle}
        </StyledText>)}
        <h3 style={{marginTop:0,}}>
          {title}
        </h3>
        {subtitle && (<StyledText>
          {subtitle}
        </StyledText>)}
        {buttonLabel && <Button label={buttonLabel} primary />}
      </StyledCtA>
    </StyledWrapper>
  )

  switch (true) {
    case typeof to !== "undefined":
      return (
        <StyledLink to={to}>
          <Cta />
        </StyledLink>
      )

    case typeof href !== "undefined":
      return (
        <StyledA href={href} target="_blank" rel="noreferrer">
          <Cta />
        </StyledA>
      )

    case typeof onClick !== "undefined":
      return <Cta onClickEvent={onClick} />

    default:
      return <Cta />
  }
}

export default CallToAction

CallToAction.propTypes = {
  suptitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      color: PropTypes.string,
      repeat: PropTypes.oneOf(["repeat", "no-repeat", "repeat-y", "repeat-x"]),
      size: PropTypes.oneOf(["cover", "contain"]),
      image: PropTypes.string,
    }),
  ]),
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  textColor: PropTypes.oneOf(["positive", "negative"]),
}

CallToAction.defaultProps = {
  textColor: "positive",
}
